import PropTypes from "prop-types"
import React from "react"
import imagePlaceholder from "../../images/hoa_logo_dark.svg"
import Badge from "./Badge"
import clsx from "clsx"
import { SanityImage } from "../../vendor/hoa/sanity_image"

export const imgClass = `absolute h-full inset-0 transition-transform transform hover:scale-105 object-cover`

const ProductImage = ({ alt, badge, image, hoverImage }) => (
  <div className="overflow-hidden pb-plp-ar relative">
    {image ? (
      <SanityImage alt={alt} className={imgClass} image={image} />
    ) : (
      <img className={imgClass} src={imagePlaceholder} />
    )}

    {hoverImage && (
      <SanityImage
        className={clsx(imgClass, "opacity-0 hover:opacity-100")}
        image={hoverImage}
        renderLoadingBackground={false}
      />
    )}

    {badge && (
      <div className="absolute left-0 top-0">
        <Badge>{badge}</Badge>
      </div>
    )}
  </div>
)

ProductImage.propTypes = {
  alt: PropTypes.string,
  badge: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hoverImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default ProductImage
