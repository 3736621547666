import { groupBy, isUndefined, last, mapValues, sortBy, uniq } from "lodash"

/**
 * Returns the default variant. This is the first variant that is in stock. If all variants are
 * out of stock then this will be the first variant.
 */
export const defaultVariant = product =>
  product.variants.find(v => v.availableForSale) || product.variants[0]

export const filterOnSelection = (items, selection) =>
  items.filter(item =>
    (item.options || []).every(
      ({ name, value }) =>
        isUndefined(selection[name]) ||
        selection[name]?.toLowerCase() === value.toLowerCase()
    )
  )

export const maxCompareAtPrice = product =>
  last(
    sortBy(
      product.variants.map(v => v.compareAtPrice).filter(p => p),
      p => Number(p)
    )
  )

/**
 * Returns array of `{ name, values }` objects for a product. Ordering from Shopify is preserved.
 * See test for specific format.
 */
export const optionsWithValues = (product, ordering = []) =>
  variantOptionsWithValues(product.variants, ordering)

const variantOptionsWithValues = (variants, ordering = []) => {
  const allOptions = variants.flatMap(variant => variant.selectedOptions)

  const unorderedValues = mapValues(
    groupBy(allOptions, option => option.name),
    options => uniq(options.map(option => option.value))
  )

  const orderedNames = uniq(
    ordering.concat(allOptions.map(option => option.name))
  ).filter(name => unorderedValues[name])

  return orderedNames.map(name => ({
    name,
    values: unorderedValues[name],
  }))
}

/**
 * Returns variant matching the provided option selection, like `{ Color: "Black", Size: "XS" }`.
 */
export const selectedVariant = (product, selection) => {
  return product.variants.find(variant =>
    Object.entries(selection).every(
      ([name, value]) => getOptionValue(variant, name) === value
    )
  )
}

const getOptionValue = (variant, name) =>
  variant.selectedOptions.find(opt => opt.name === name)?.value

/**
 * Returns the option selection for a variant, like `{ Color: "Black", Size: "XS" }`.
 */
export const variantSelection = variant =>
  variant.selectedOptions.reduce(
    (acc, { name, value }) => ({
      ...acc,
      [name]: value,
    }),
    {}
  )
