import styled from "@emotion/styled"

const Badge = styled.div`
  align-items: center;
  background: var(--color-default-text);
  color: var(--color-light);
  display: flex;
  font-size: 0.625rem;
  height: 32px;
  justify-content: center;
  line-height: 1.3;
  min-width: 140px;
  padding: 0 15px;
  text-align: center;
  text-transform: uppercase;
`

export default Badge
