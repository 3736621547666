import styled from "@emotion/styled"
import { Link } from "gatsby"
import { observer } from "mobx-react-lite"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import ProductImage, { imgClass } from "./ProductImage"
import StoreContext from "../context/StoreContext"
import formatCurrency from "../../lib/formatCurrency"
import { getBadge } from "../../lib/shopify/productTags"
import { maxCompareAtPrice } from "../../lib/shopify/productUtil"

const formatPriceRange = ({ minVariantPrice, maxVariantPrice }) => {
  if (minVariantPrice === maxVariantPrice) {
    return formatCurrency(minVariantPrice)
  } else {
    return `${formatCurrency(minVariantPrice)} - ${formatCurrency(
      maxVariantPrice
    )}`
  }
}

const ProductLink = styled(Link)`
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 5px;
`

const ProductTitle = styled.div`
  font-size: 0.8125rem;
  font-weight: var(--weight-slight-bold);
  margin-bottom: 5px;
`

const ProductPrice = styled.div`
  font-size: 0.75rem;
`

const CompareAtPrice = styled.span`
  color: var(--color-disabled);
  padding-right: 8px;
  text-decoration: line-through;
`

const CollectionProduct = ({ product }) => {
  const { productStore } = useContext(StoreContext)

  const badge =
    product.customInventoryType || !productStore.isProductAvailable(product.id)
      ? "Out of Stock"
      : getBadge(product)

  const compareAtPrice = maxCompareAtPrice(product)

  return (
    <ProductLink to={product.path.current}>
      <ProductImage
        badge={badge}
        image={product.plpImage}
        hoverImage={product.plpImageHover}
      />

      <div>
        <ProductTitle>{product.title}</ProductTitle>
        <ProductPrice>
          {compareAtPrice && (
            <CompareAtPrice>{formatCurrency(compareAtPrice)}</CompareAtPrice>
          )}
          {formatPriceRange(product.priceRange)}
        </ProductPrice>
      </div>
    </ProductLink>
  )
}

CollectionProduct.propTypes = {
  product: PropTypes.object.isRequired,
}

export const SearchCollectionProduct = observer(({ product }) => {
  const productPrice = formatPriceRange({
    minVariantPrice: product.variants_min_price,
    maxVariantPrice: product.variants_max_price,
  })
  return (
    <ProductLink to={`/products/${product.handle}`}>
      <div className="overflow-hidden pb-plp-ar relative">
        <img className={imgClass} src={product.image} />
      </div>

      <div>
        <ProductTitle>{product.title}</ProductTitle>
        <ProductPrice>{productPrice}</ProductPrice>
      </div>
    </ProductLink>
  )
})

SearchCollectionProduct.propTypes = {
  product: PropTypes.object.isRequired,
}

export default observer(CollectionProduct)
